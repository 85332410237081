import { template as template_937206a47f1b43ebbc129c7629ee94f6 } from "@ember/template-compiler";
const FKLabel = template_937206a47f1b43ebbc129c7629ee94f6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
