import { template as template_22737aabae8e43d0b551b2741b83017f } from "@ember/template-compiler";
const FKText = template_22737aabae8e43d0b551b2741b83017f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
