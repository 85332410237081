import { template as template_d2d59d7a0eba4dadaa229a36b4d7b3f3 } from "@ember/template-compiler";
const WelcomeHeader = template_d2d59d7a0eba4dadaa229a36b4d7b3f3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
