import { template as template_102755026dca419ea44bc2c02ad7f310 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_102755026dca419ea44bc2c02ad7f310(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
